<script setup>
    import { inject, computed, ref } from "vue";
    import { InjectionKeys } from 'o365-utils';

    import AppButton from 'o365.pwa.vue.components.layout.AppButton.vue';

    const { pwaStoreKey } = InjectionKeys;

    const { state: offlineState } = inject(pwaStoreKey);

    const props = defineProps(['pwaState']);

    const pwaState = ref(props.pwaState);
    
    const hasDatabaseConnection = computed(() => {
        return offlineState?.pwaState?.hasDatabaseConnection ?? false;
    });

    const appInstalled = computed(() => {
        return pwaState.value?.isAppInstalled ?? null;
    });
    const appHasUpdate = computed(() => {
        return pwaState.value?.hasAppUpdateAvailable ?? null;
    });

    const appStatusClass = computed(() => {
        if (appInstalled.value === null && appHasUpdate.value === null) {
            return 'bi-info-circle text-danger';
        }

        if (!appInstalled.value || appHasUpdate.value) {
            return 'bi-info-circle text-primary';
        }
        
        return 'bi-check-circle text-success';
    });

    const appStatusText = computed(() => {
        if (appInstalled.value === null && appHasUpdate.value === null) {
            return $t('Unknown status');
        }

        if (!appInstalled.value) {
            return $t('App install available');
        }

        if (appHasUpdate.value) {
            return $t('App update available');
        }

        return $t('Installed and up to date');
    });
</script>

<template v-if="initialized">
    <div class="d-flex flex-column bg-light gap-2 p-2">
        <h4 class="m-0">{{ $t('App Status') }}</h4>
    
        <hr class="m-0"/>

        <AppButton :buttonText="appStatusText" :iconClass="appStatusClass"></AppButton>
        
        <AppButton :buttonText="$t('Server connection')" iconClass="bi-server" showIconStatus :iconStatusState="hasDatabaseConnection"></AppButton>
    </div>
</template>